/* Dropdown.css */

.dropdown {
  position: relative;
}

.dropdown-button {
  background-color: white;
  cursor: pointer;
  border-bottom: 1px solid #000814;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.dropdown-options {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.dropdown-option {
  padding: 8px 8px;
  cursor: pointer;
  transition: background 0.2s;
}

.dropdown-menu-option {
  padding: 8px 8px;
  cursor: pointer;
  transition: background 0.2s;
  color: #000814;
}

.dropdown-option:hover {
  background-color: #EBEBEB;
}

.dropdown-menu-option:hover {
  background-color: #000814;
  color: white !important;
}

/* Transition classes */

.dropdown-transition-enter {
  opacity: 0.01;
  transform: translateY(-20px);
}

.dropdown-transition-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 200ms, transform 200ms;
}

.dropdown-transition-exit {
  opacity: 1;
  transform: translateY(0px);
}

.dropdown-transition-exit-active {
  opacity: 0.01;
  transform: translateY(-20px);
  transition: opacity 200ms, transform 200ms;
}
