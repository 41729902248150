.carousel {
  position: relative;
  overflow: hidden;
}

.carousel__button {
  position: absolute;
  top: 45%;
  border: none;
  padding: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.carousel__button--prev {
  z-index: 5;
  left: 10px;
}

.carousel__button--next {
  right: 10px;
}

.carousel__images {
  display: flex;
  transition: transform 0.5s ease;
  height: 100%;
}

.carousel__image {
  width: 100%;
  height: 100%; /* Add a height if not present */
  flex-shrink: 0;
  object-fit: cover; /* Or use 'cover' if you want the image to cover the entire carousel */
}
