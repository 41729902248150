::placeholder {
  color: white;
}

.lines-img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile {
  display: none;
}

.hero-text {
  text-align: center;
  width: 60%;
  position: absolute;
  top: 25%;
  right: 0;
  left: 0;
  margin: auto;
}

.outline-button {
  display: inline-block;
  padding: 12px 24px;
  border: 1px solid white;
  cursor: pointer;
  color: white;
  transition: all 0.5s ease;
}

.outline-button-dark {
  display: inline-block;
  padding: 12px 24px;
  border: 1px solid white;
  cursor: pointer;
  color: white;
  transition: all 0.3s ease;
}

.destination-highlighted {
  border-radius: 50px;
  border: 1px solid #88A9C3;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 8px;
}

.destination-button-text {
  transition: all 0.4s ease;
}

.listing-row {
  position: relative;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 72px;
  padding-right: 72px;
  box-sizing: border-box;
  width: 100vw;
  height: 31vw;
  align-items: center;
}

.listing-item {
  flex: 0 0 auto;
  position: relative;
  margin-left: 24px;
  width: 26vw;
  height: 30vw;
  transition: width 0.5s, height 0.5s;
  cursor: pointer;
}

.listing-img {
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.listing-dim {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.35);
}


.listing-text {
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 90%;
}

.quality-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70vw;
  margin-top: 64px;
}

.quality-banner {
  margin-top: 300px;
  padding-left: 20vw;
  padding-right: 20vw;
  padding-top: 36px;
  padding-bottom: 36px;
  background-color: #141414;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.quality-number {
  font-family: 'Jost-ExtraLight';
  font-size: 96px;
}

.quality-img {
  width: 30vw;
  height: 70vh;
  object-fit: contain;
}

.quality-info {
  margin-top: 8vh;
  width: 30vw;
}

.column {
  display: flex;
  flex-direction: column;
}

.mobile-column {
  flex-direction: column;
}

.Toastify__toast {
  border-radius: 0px;
}

.Toastify__close-button {
  display: none;
}

.toast-body {
  text-align: center;
  font-family: 'Jost-Light';
  color: black;
  font-size: 20px;
  line-height: 1.4em;
}

div[disabled]
{
  pointer-events: none;
  background-color: lightgray;
}

.Toastify__progress-bar {
  visibility: hidden;
}

.mobile-column {
  flex: 1;
  display: flex;
  justify-content: space-around;
}

.sound-button {
  position: absolute;
  top: 12vh;
  left: 36px;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  z-index: 2;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.5s ease;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.carousel-header {
  margin-left: 96px;
}

.sound-button-icon {
  height: 60px;
  width: 60px;
}

.desktop {
  display: block;
}

.App {
  width: 100%;
  height: 100%;
}

.animated-button {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.landing-image-hero {
  object-fit: cover;
  width: 100vw;
  height: 75vh;
  background-color: black;
  margin-bottom: -6px;
}

.landing-video-hero {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  background-color: black;
  margin-bottom: -6px;
}

.video-dim {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.4);
}

.email-signup-section {
  z-index: 1;
  margin-top: -64px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.email-input {
  z-index: 1;
  width: 40%;
  background-color: rgba(0,0,0,0);
  border-style: none;
  color: white;
  border-bottom: 1px solid white;
  font-family: 'Jost-Light';
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-size: 20px;
  text-align: center;
}

.email-input:focus {
  outline: none;
}

.body-container {
  margin-top: 120px;
}

.collage-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-animation {
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  height: 70vw;
}

.product-animation-mobile {
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  height: 300vw;
}

.collage-img {
  width: 100%;
  object-fit: cover;
}

.absolute {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
}

.masonry-section {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
}

.masonry-img {
  margin-top: 64px;
  width: 40vw;
}

.masonry-body {
  display: flex;
  align-items: center;
}

.masonry-text-container {
  display: flex;
  flex-direction: column;
  padding: 72px;
}

.waitlist-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.email-button {
  z-index: 2;
  margin-left: 12px;
  background-color: #FFFFFF;
  cursor: pointer;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 900px) {

  .carousel-info-container {
    width: 100%;
  }

  .body-container {
    margin-top: 120px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .email-input {
    width: 90vw;
    font-family: 'Jost-Light';
    text-transform: uppercase;
    letter-spacing: 0.3em;
    font-size: 16px;
    text-align: center;
    border-radius: 0px;
  }

  .button-text {
    font-size: 18px;
  }

  .hidden {
    display: none;
  }

  .landing-video-hero {
    height: 92vh;
  }

  .waitlist-banner {
    margin-top: 48px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 48px;
    padding-bottom: 48px;
    height: auto;
  }

  .masonry-text-container {
    padding: 12px;
  }

  .masonry-body {
    padding: 12px;
    flex-direction: column;
  }

  .masonry-img {
    margin-top: 24px;
    width: 110vw;
  }

  .masonry-section {
    margin-top: 96px;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  .vertical-spacing-xlarge {
    margin-top: 36px;
  }

  .carousel-info-mobile {
    margin-top: 24px;
  }

  br {
    display: inline;
  }

  .collage-img {
    width: 100%;
  }


  .carousel-header {
    margin-left: 24px;
  }

  .collage-section {
    margin-top: 24px;
  }

  .curation-section {
    margin-top: 0px;
  }

  .quality-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    width: 85%;
    margin-top: 48px;
  }

  .quality-banner {
    margin-top: 24px;
    padding: 24px;
    text-align: center;
  }

  .quality-number {
    font-family: 'Jost-Light';
    font-size: 96px;
  }

  .quality-img {
    width: 100%;
    height: auto;
  }

  .quality-info {
    margin-top: 0px;
    width: 100%;
  }

  .hero-text {
    width: 85%;
  }

  .listing-row {
    height: 54vh;
    width: 100vw;
    padding-left: 0px;
    padding-right: 24px;
    align-items: center;
  }

  .listing-item {
    flex: 0 0 auto;
    position: relative;
    margin-left: 24px;
    width: 35vh;
    height: 50vh;
    transition: width 0.5s, height 0.5s;
    cursor: pointer;
  }

  .outline-button {
    padding: 12px 0px;
    width: 100%;
  }

  .outline-button-dark {
    padding: 12px 0px;
    width: 100%;
  }
}
