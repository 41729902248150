.destination-text {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.destination-item {
  flex: 0 0 auto;
  position: relative;
  margin-right: 24px;
  width: 20vw;
  height: 25vw;
  transition: all 0.4s ease;
  cursor: pointer;
}

.destination-selected {
  width: 25vw;
  height: 30vw;
  border: 2px solid white;
}

.destination-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.result-row {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.result-container {
  margin-top: 48px;
  width: 48%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.result-img {
  border-radius: 10px;
  width: 100%;
  height: 50vh;
  object-fit: cover;
  transition: all 0.4s ease;
  cursor: pointer;
}

.result-divider {
  height: 1px;
  width: 100%;
}

.category-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.category-button {
  width: 30%;
  cursor: pointer;
  transition: all 0.4s ease;
}

@media only screen and (max-width: 900px) {

  .listing-row {
    width: 100%;
  }

  .result-img {
    height: 50vh;
  }

  .destination-item {
    width: 50vw;
    height: 60vw;
  }

  .destination-selected {
    width: 60vw;
    height: 75vw;
    border: 2px solid white;
  }

  .feature-row {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .result-row {
    width: 90%;
    flex-direction: column;
  }

  .result-container {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
  }
}
