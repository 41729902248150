.guide-header-text {
  text-align: left;
  position: absolute;
  top: 55%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
}

.input-light {
  width: 100%;
  background-color: rgba(0,0,0,0);
  border-style: none;
  border-bottom: 1px solid white;
  border-radius: 0px;
  text-align: left;
  padding: 0px;
}

.fullscreen-modal {
  overflow-y: scroll;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  z-index: 9;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
