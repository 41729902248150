@media (hover: hover) {

  .v2-button-text {
    transition: all 0.4s ease;
  }

  .v2-button-text:hover {
    font-size: 14.5px;
    font-family: 'Jost-Regular';
  }

  .v2-button-line:hover {
    width: 60%;
  }

  .button:hover {
    padding: 13px 25px;
    background-color: #B5DEFF;
  }

  .secondary-header3-hover:hover {
    font-size: 14.25px;
  }

  .body2-hover:hover {
    font-size: 18.25px;
  }

  .body3-hover:hover {
    font-size: 16.25px;
  }

  .nav-header:hover {
    font-size: 33px;
  }

  .footer-header:hover {
    font-size: 25px;
  }

  a:hover { text-decoration: none; }

  .outline-button:hover {
    color: black;
    background-color: #88A9C3;
    border: 0px;
  }

  .outline-button-dark:hover {
    color: white;
    background-color: black;
    border: 1px solid black;
  }

  .destination-button-text:hover {
    font-size: 15px;
    color: white;
  }

  .listing-item:hover {
    width: 27vw;
    height: 31vw;
  }

  .sound-button:hover {
    width: 72px;
    height: 72px;
  }

  .category-button:hover {
    font-size: 17px;
    font-family: 'Jost-Regular';
    color: white;
  }

  .destination-item:hover {
    width: 25vw;
    height: 30vw;
  }

  .result-img:hover {
    width: 101%;
    height: 51vh;
  }

  .view-all-button:hover {
    font-size: 15px;
  }

  .gallery-masonry-img:hover {
    width: 101%;
  }

  .category-text:hover {
    font-size: 19px;
  }

  .carousel-button:hover {
    width: 30px;
    height: 30px;
  }

  .see-all-button:hover {
    padding: 14px 26px;
    background-color: #B5DEFF;
  }

  .book-button:hover {
    width: 101%;
    padding: 13px;
    background-color: #B5DEFF;
  }

  .highlight-hero:hover {
    width: 100.5%;
    height: 55.5vh;
  }

  .highlight-media:hover {
    width: 49.5%;
    height: 30.5vh;
  }

  .x-icon:hover {
    width: 40px;
    height: 40px;
  }
}
