.letter-back-img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.letter-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 8;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow-y: scroll;
}

.text-container {
  padding-top: 48px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.signature-img {
  height: 100px;
}

.letter-divider {
  height: 1px;
  width: 100%;
}

.letter-dim {
  background-color: rgba(0,0,0,0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

@media only screen and (max-width: 900px) {
  .text-container {
    width: 90%;
  }
}
